import { Component } from '@angular/core';
import { TERM_DATES_ROUTE } from '../routes';

@Component({
  selector: 'as-term-dates-page',
  template: `
    <div class="page-padding">
      <h2>Term Dates</h2>

      <h3>After-School Lessons</h3>

      <h4>Summer Term 2024</h4>

      <b>Tuesdays</b>

      <p>
        4:00 - 6:00 PM<br />
        30th April - 16th July (11 weeks)<br />
        No swimming half term week commencing 27th May
      </p>

      <b>Thursdays</b>
      <p>
        4:00 - 6:00 PM<br />
        2nd May - 18th July (11 weeks)<br />
        No swimming half term week commencing 27th May
      </p>

      <h3>Parent & Baby / Toddler</h3>

      <h4>Summer Term 2024</h4>

      <b>Wednesdays</b>

      <p>
        9:30 - 11:30 AM<br />
        24th April - 10th July (11 weeks)<br />
        No swimming half term week commencing 27th May
      </p>
    </div>
  `,
})
export class TermDatesPageComponent {}
