// Angular
import { Component } from '@angular/core';

// Routes
import { MERMAID_ROUTE } from '../routes';

@Component({
  selector: 'as-lessons-page',
  template: `
    <div class="page-padding">
      <h2>Weekly Lessons</h2>

      <h3>Parent and Baby / Toddler / Pre-School Lessons</h3>

      <p>
        These lessons are held on Wednesday mornings at our Staines venue during
        term time. Our groups are small, with a maximum of 6 per class. The pool
        is kept nice and toasty at 32 degrees.
      </p>

      <p>
        Our parent and baby / toddler lessons are designed for children from 0-4
        years old. These lessons have many benefits, and are a great way to
        start your little one's swimming journey! We make lessons fun and
        enjoyable to develop confidence and water safety. These lessons are also
        a great way to bond through exercise and we develop children's swimming
        skills to independent swimming when each individual child is ready.
      </p>

      <p>
        Children aged 3 years and older do not need to be accompanied by a
        parent in the water.
      </p>

      <h3>After-School Lessons</h3>

      <p>
        Our weekly lessons are held after school on Tuesdays and Thursdays at
        our Chertsey venue. Our group lessons are small ratio and range from
        non-swimmers to advanced. We also offer 1-1 and 2-1 lessons. Children
        can join our lessons from 3 years and older.
      </p>

      <as-content-image
        filename="photos/swimmer1_cropped.jpg"
      ></as-content-image>

      <h2>Crash Courses</h2>
      <p>
        Crash courses are a great opportunity to build confidence, improve
        technique and learn new skills. Group and private (1-1 and 2-1) lessons
        are available, each 30 minutes long. We run crash courses during the
        school holidays for all ages from 3 upwards.
      </p>

      <h3>Summer 2023</h3>

      <h4>Egham</h4>

      <ul>
        <li>Tuesday 30th May to Friday 2nd June (9:00 - 11:00 AM)</li>
        <li>Monday 24th to Friday 28th July (9:00 AM - 12:00 PM)</li>
      </ul>

      <h4>Twyford</h4>

      <ul>
        <li>Monday 7th to Friday 11th August (9:00 - 11:00 AM)</li>
      </ul>

      <h2>Mermaid Swimming</h2>
      <p>
        As well as swimming lessons, our sister company Mermaid Bay Experiences
        offer mermaid experiences and parties. For more information on these
        please see the
        <a [href]="mermaidLink">Mermaids</a> tab!
      </p>
      <as-content-image
        filename="photos/mermaid6_cropped.jpg"
      ></as-content-image>
    </div>
  `,
})
export class LessonsPageComponent {
  readonly mermaidLink = MERMAID_ROUTE;
}
