import { Component } from '@angular/core';

@Component({
  selector: 'as-venues-page',
  template: `
    <div class="page-padding">
      <h2>Venues</h2>

      <p>All pools are heated!</p>

      <h3>Chertsey</h3>
      <as-content-image filename="photos/chertsey.jpg"></as-content-image>

      <h3>Staines</h3>
      <as-content-image filename="photos/staines.jpg"></as-content-image>

      <h3>Egham</h3>

      <as-content-image filename="photos/egham.jpg"></as-content-image>

      <h3>Twyford</h3>

      <as-content-image filename="photos/twyford.jpg"></as-content-image>

      <h3>Addlestone Moor (currently under refurbishment)</h3>

      <as-content-image
        filename="photos/addlestone_moor.jpg"
      ></as-content-image>
    </div>
  `,
})
export class VenuesPageComponent {}
